const cloudToolsRouter = [{
		path: '/cloudTools',
		meta: {
			title: '云工具',
			icon: 'tool'
		},
		component: () => import('@/views/cloudTools/index'),

		// hidden: true,


	}, {
		path: '/cloudTools/fastaSequenceExtract',
		component: () => import('@/views/cloudTools/components/fastaSequenceExtract'),
		name: 'fastaSequenceExtract',
		meta: {
			title: 'fasta序列提取',
		}
	},
	{
		path: '/cloudTools/phylogeneticTree',
		component: () => import('@/views/cloudTools/components/phylogeneticTree'),
		name: 'phylogeneticTree',
		meta: {
			title: '系统进化树',
		}
	},
	{
		path: '/cloudTools/venn',
		component: () => import('@/views/cloudTools/components/venn'),
		name: 'venn',
		meta: {
			title: '韦恩图',
		}
	},
	{
		path: '/cloudTools/2DPCA',
		component: () => import('@/views/cloudTools/components/2DPCA'),
		name: '2DPCA',
		meta: {
			title: '二维PCA',
		}
	},
	{
		path: '/cloudTools/PCoA',
		component: () => import('@/views/cloudTools/components/PCoA'),
		name: 'PCoA',
		meta: {
			title: 'PCoA主坐标分析',
		}
	},
	{
		path: '/cloudTools/tableExtract',
		component: () => import('@/views/cloudTools/components/tableExtract'),
		name: 'tableExtract',
		meta: {
			title: '表格信息提取',
		}
	},
	{
		path: '/cloudTools/tableRepeat',
		component: () => import('@/views/cloudTools/components/tableRepeat'),
		name: 'tableRepeat',
		meta: {
			title: '表格ID信息去重',
		}
	},
	{
		path: '/cloudTools/tableMerge',
		component: () => import('@/views/cloudTools/components/tableMerge'),
		name: 'tableMerge',
		meta: {
			title: '双表合并',
		}
	}
]
export default cloudToolsRouter