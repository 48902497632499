<template>
	<div style="height: 60px;background: #31a1ff;">
		<!-- logo -->
		<div class="logo"></div>
		<div style="float: right;padding-top: 10px;width: 129px;">
			<div v-if="loginStatus == 'unLogin'">
				<div style="float: left;">
					<el-avatar :size="40" :src="userAtar"></el-avatar>
				</div>
				<div style="float: left; padding-left: 15px; padding-top: 9px; color:black;height: 21px;width: 50px">
					<span style="cursor: pointer;
		user-select: none;color:#c7e5ff;font-size:14px;" @click="login">请登录</span></div>
			</div>
			<div v-else style="margin-right: 20px">
				<el-dropdown>
					<div style="cursor: pointer;user-select: none">
						<div style="float: left;">
							<!-- <el-avatar :size="40" :src="this.avatar ? avatar : userAtar" @error="errorHandler"></el-avatar> -->
							<el-image class="auater" :src="this.avatar ? avatar : userAtar"
								@error="handleError($event)"></el-image>
						</div>
						<div
							style="float: left; padding-left: 15px; padding-top: 11px; color:#c7e5ff;height: 21px;width: 50px;display: -webkit-box;overflow: hidden;-webkit-line-clamp: 1; font-size:14px; ">
							<span>{{ username }}</span>
						</div>
					</div>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item>
							<div @click="cellcet">个人中心</div>
						</el-dropdown-item>
						<el-dropdown-item>
							<div @click="logout">退出登录</div>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
		<!-- 导航栏 -->
		<div class="ccss">
			<el-menu :default-active="activeIndex" mode="horizontal" :ellipsis="false" @select="handleSelect"
				background-color="#31a1ff" text-color="#c7e5ff" active-text-color='#ffffff'>
				<el-menu-item index="">
					<template slot="title">
						<div class="title-box">
							<div>首页</div>
							<div :class="activeIndex == '' ? 'title-bom' : 'title-a'">
							</div>
						</div>
					</template>
				</el-menu-item>
				<el-menu-item index="cloudTools">
					<template slot="title">
						<div class="title-box">
							<div>云工具</div>
							<div :class="activeIndex == 'cloudTools' ? 'title-bom' : 'title-a'">
							</div>
						</div>
					</template>

				</el-menu-item>
				<el-menu-item index="article">
					<template slot="title">
						<div class="title-box">
							<div>文章</div>
							<div :class="activeIndex == 'article' ? 'title-bom' : 'title-a'">
							</div>
						</div>
					</template>

				</el-menu-item>
				<el-menu-item index="about">
					<template slot="title">
						<div class="title-box">
							<div>关于我们</div>
							<div :class="activeIndex == 'about' ? 'title-bom' : 'title-a'">
							</div>
						</div>
					</template>
				</el-menu-item>
			</el-menu>
		</div>
	</div>
</template>

<script>
	import userA from '@/assets/404_images/userA.png'
	import {
		getUserById,
	} from '@/api/user'
	import {
		getToken,
		getUserId,
		getUserName,
		getUserAvatar,
		removeToken,
		removeUserId,
		removeUnitRole,
		removeUserName,
		removeUserAvatar,
		removeUnitsId
	} from '@/utils/auth'
	export default {
		name: 'HomeIndex',
		data() {
			return {
				state: 'false',
				activeIndex: '1',
				loginStatus: 'unLogin',
				userToken: '',
				userId: '',
				avatar: '',
				username: '',
				url: '@/views/layout/public/mian.pang',
				userAtar: userA
			}
		},
		created() {
			if (this.$route.name == '/' || this.$route.name == 'getHotsList') {
				this.activeIndex = ''
			} else {
				this.activeIndex = this.$route.name
			}
			this.getAutar()
			this.userToken = getToken()
			this.userId = getUserId()
			this.loginStatus = this.userToken ? 'login' : 'unLogin'
			if (getUserId()) {
				this.userInformation()
			} else {

			}
		},
		methods: {
			handleError(event) {
				event.currentTarget.src = "/userA.png"
			},
			getAutar() { //头像
				getUserById(getUserId()).then(res => {
					// console.log(11111,res)
					if (res.avatar)
						// console.log(11111,res)
						return this.avatar = `${this.$VUE_APP_PIC_API}/flieUploads/${res.avatar}`;
				}).catch(error => {

				})
			},
			async logout() {
				console.log(11111);
				this.$router.push('')
				window.location.reload()
				removeUnitRole(),
					removeToken(),
					removeUserId(),
					removeUserName(),
					removeUnitsId(),
					removeUserAvatar()
			},
			handleSelect(key, keyPath) {
				this.activeIndex = key
				if (key == "about") {
					window.open('https://www.gzcompute.com/#/aboutUsPage?type=0&id=61dfd0697ca945075c939419');
				} else {
					this.$router.push(`/${key}`)
				}
			},
			login() {
				this.$router.push('/login')
			},
			cellcet() {
				this.$router.push('/unitInfo')
			},
			cellcet1() {
				this.$router.push('/test')
			},
			userInformation() {
				getUserById(getUserId()).then(res => {
					// this.avatar = res.avatar
					this.username = res.name
				}).catch(error => {

				})
			},

		}
	}
</script>

<style lang="scss" scoped>
	.auater {
		width: 40px;
		height: 40px;
		border-radius: 25px;
	}
	.ccss {
		float: right;
		padding-right: 30px;

		.el-menu--horizontal>.el-menu-item {
			line-height: 0;
			border-bottom-color: rgba(80, 175, 255, 1) !important;

			// margin: 0 10px !important;
			padding-top: 32px !important;

		}

		.el-dropdown-menu__item,
		.el-menu-item {
			padding: 0 0 !important;
			width: 80px;
		}

		.el-menu-item:focus,
		.el-menu-item:hover {
			background-color: rgba(80, 175, 255, 1) !important;
		}

		.el-menu.el-menu--horizontal {
			border-bottom: solid 0px #e6e6e6;
		}
	}

	.logo {
		background: url('@/views/layout/public/logo.png');
		background-size: 100%;
		width: 238px;
		height: 33px;
		float: left;
		margin-left: 24px;
		margin-top: 11px
	}


	.buttonTitle {
		background: url('@/views/layout/public/button.png');
		width: 56px;
		height: 4px;
		/* float: left; */
		margin-top: 57px;
	}

	.title-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.title-bom {
		width: 100% !important;
		height: 4px !important;
		background: #ffffff !important;
		border-radius: 4px 4px 0px 0px !important;
		margin-top: 24px !important;
	}

	.title-a {}
</style>