import request from '@/utils/request'

export function getUserById(id, filter) {
	return request({
		url: '/users/' + id,
		method: 'get',
		params: {
			filter
		}
	})
}

export function upUserById(id, data) {
	return request({
		url: '/users/' + id,
		method: 'patch',
		data
	})
}

export function getUnitIdByUserId(id, query) {
	return request({
		url: '/users/' + id + '/unit',
		method: 'get',
		params: query
	})
}

export function userLogin(data) {
	return request({
		url: '/users/login',
		method: 'post',
		data
	})
}

//头像
export function avatar(fileId, fileName) {
	return request({
		url: '/flieUploads/' + fileId + '/files/' + fileName,
		method: 'get',
	})
}


export function dowonloadImage(query) {
	return request({
		url: '/flieUploads/dowonloadImage/',
		method: 'get',
		params: query
	})
}