import Vue from 'vue';
import App from './App.vue';
import router from './router';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueCookies from 'vue-cookies';
import jsCookie from 'js-cookie';
import Vuex from 'vuex';
import store from './store';
import vueEsign from 'vue-esign';
import moment from 'moment';
import JsonExcel from 'vue-json-excel';
var XLSX = require('xlsx');
// import XLSX from 'xlsx';


Vue.component('downloadExcel', JsonExcel);

Vue.prototype.$moment = moment;
Vue.prototype.$xlsx = XLSX;
Vue.prototype.$VUE_APP_PIC_API = process.env.VUE_APP_BASE_API

Vue.use(vueEsign);
Vue.use(Vuex);

Vue.prototype.$cookie = jsCookie;

Vue.use(VueCookies);
Vue.use(ElementUI);

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app');