<template>
	<div style="height: 60px;">
		<div style="float: right;padding-top: 10px;width: 129px;">
			<div v-if="loginStatus != 'unLogin'">
				<el-dropdown>
					<div style="cursor: pointer;user-select: none">
						<div style="float: left;">
							<!-- <el-avatar v-if="avatar" :size="40" :src="avatar" @error="handleError($event)">
							</el-avatar>
							<el-avatar v-else :size="40" :src="userAtar">
							</el-avatar> -->
							<el-image class="auater" :src="avatar ? avatar : userAtar" @error="handleError($event)"></el-image>
						</div>
						<div
							style="float: left; padding-left: 15px; padding-top: 11px; color:#81c5ff;height: 21px;width: 50px;display: -webkit-box;overflow: hidden;-webkit-line-clamp: 1; font-size:14px; ">
							<span>{{ username }}</span>
						</div>
					</div>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item>
							<div @click="logout">退出登录</div>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
		<!-- 导航栏 -->
		<div class="ccss">
			<el-menu :default-active="activeIndex" mode="horizontal" :ellipsis="false" @select="handleSelect"
				text-color="#81c5ff">
				<el-menu-item index="">首页</el-menu-item>
			</el-menu>
		</div>
	</div>
</template>

<script>
	import userA from '@/assets/404_images/userA.png'
	import {
		getUserById,
	} from '@/api/user'
	import {
		getToken,
		getUserId,
		removeToken,
		removeUserId,
		removeUnitRole,
		getUserName,
		getUserAvatar,
		removeUserName,
		removeUserAvatar
	} from '@/utils/auth'
	export default {
		name: 'HomeIndex',
		data() {
			return {
				activeIndex: '1',
				loginStatus: 'unLogin',
				userToken: '',
				userId: '',
				avatar: '',
				username: '',
				userAtar: userA
			}
		},
		created() {
			this.getAutar()
			this.userToken = getToken()
			this.userId = getUserId()
			this.loginStatus = this.userToken ? 'login' : 'unLogin'
			if (getUserId()) {
				this.userInformation()
			} else {

			}
		},
		methods: {
			handleError(event) {
				event.currentTarget.src = "/userA.png"
			},
			getAutar() { //头像
				getUserById(getUserId()).then(res => {
					if (res.avatar) {
						return this.avatar = `${this.$VUE_APP_PIC_API}/flieUploads/${res.avatar}`
					}
				}).catch(error => {

				})
			},
			async logout() {
				this.$router.push('/login')
				removeUnitRole(),
					removeToken(),
					removeUserId(),
					removeUserName(),
					removeUserAvatar()
			},
			handleSelect(key, keyPath) {
				this.$router.push(`/${key}`)
			},
			login() {
				this.$router.push('/login')
			},
			userInformation() {
				getUserById(getUserId()).then(res => {
					// this.avatar = res.avatar
					this.username = res.name
				}).catch(error => {

				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.auater{
		width: 40px;
		height: 40px;
		border-radius: 25px;
	}
	
	.ccss {
		float: right;
	}

	.appText {
		/* background: url('@/views/layout/public/text.png'); */
		margin-top: 13px;
		margin-left: 15px;
		width: 22px;
		height: 13px;
		float: left;
		cursor: pointer;
		user-select: none;
	}

	.el-dropdown {
		display: inline-block;
		position: relative;
		color: #606266;
		font-size: 14px;
		margin-left: 20px;
	}
</style>