<template>
	<el-container>
		<el-header style="padding: 0px;">
			<appHeader></appHeader>
		</el-header>
		<el-main class="Smain" style="padding: 0px;">
			<router-view></router-view>
		</el-main>
	</el-container>
</template>

<script>
	import appHeader from './components/appHeader.vue'

	export default {
		data() {
			return {}
		},
		components: {
			appHeader: appHeader,
		}
	};
</script>

<style>
	.Smain {
		height: 100vh;
		background:#f5f5f5;
		padding-bottom: 0 !important;
		padding-top: 0 !important;
		background-color: white;
	}


</style>