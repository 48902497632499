<template>
	<div class="color">
		<el-container>
			<el-aside class="NaVMenu" width="200px">
				<sliderBar></sliderBar>
			</el-aside>
			<el-container>
				<el-header class="heard">
					<appHeader></appHeader>
				</el-header>
				<el-main class="main">
					<div>
						<router-view></router-view>
					</div>
				</el-main>
			</el-container>
		</el-container>
	</div>
</template>

<script>
import appHeader from './components/appHeader.vue'
import sliderBar from './components/sliderBar.vue'


export default {
	data() {
		return {}
	},
	components: {
		appHeader: appHeader,
		sliderBar: sliderBar,
	}
};
</script>

<style>
.main {
	background: #f5f5f5;
	/* border-radius: 0px 0px 0px 20px; */
	padding-bottom: 0 !important;
	/* overflow-y: auto; */
}

.heard {
	height: 60px;
	background: #ffffff;
	border-radius: 20px 0px 0px 0px;

}

.NaVMenu {
	height: 100vh;
}

.color {
	background: url('../layout/public/Background.png');
	background-color: #31a1ff;
}

/* @media only screen and (max-height: 600px) {
	.main {
		overflow: auto;
	}
} */

@media only screen and (max-height: 800px) {
	.main {
		height:900px;
		/* overflow-y: scroll; */
	}
}

@media only screen and (max-height: 700px) {
	.main {
		height:900px;
		/* overflow-y: scroll; */
	}
}
</style>