// import Cookies from 'js-cookie'

// const TokenKey = 'Suanjia-Token'

// export function getToken() {
//   return JSON.parse(Cookies.get(TokenKey) || null)
// }

// export function setToken(token) {
//   return Cookies.set(TokenKey, token)
// }

// export function removeToken() {
//   return Cookies.remove(TokenKey)
// }

// export function getAccessKey() {
//   return JSON.parse(Cookies.get('AccessKey') || null)
// }

// export function setAccessKey(AccessKey) {
//   return Cookies.set('AccessKey', JSON.stringify(AccessKey))
// }

// export function removeAccessKey() {
//   return Cookies.remove('AccessKey')
// }u

// export function setUserId(userId) {
//   return localStorage.setItem('userId', id)
// }

// export function getUserId() {
//   return localStorage.getItem('id')
// }

// export function removeUserId() {
//   return localStorage.removeItem('id')
// }
import Cookies from 'js-cookie'

const TokenKey = 'User-Token'
const UserIdKey = 'User-Id'
const UserName = 'UserName'
const UserAvatar = 'UserAvatar'
const UnitsIdKey = 'UnitsId'
const UnitRole = 'Unitrole'

export function setUnitsId(token) {
	return Cookies.set(UnitsIdKey, token)
}

export function getUnitsId() {
	return Cookies.get(UnitsIdKey) || null
}

export function removeUnitsId() {
	return Cookies.remove(UnitsIdKey)
}


export function setUnitRole(token) {
	return Cookies.set(UnitRole, token)
}

export function getUnitRole() {
	return Cookies.get(UnitRole) || null
}

export function removeUnitRole() {
	return Cookies.remove(UnitRole)
}

export function getUserId() {
	return Cookies.get(UserIdKey) || null
}

export function setUserId(token) {
	return Cookies.set(UserIdKey, token)
}

export function removeUserId() {
	return Cookies.remove(UserIdKey)
}

export function getUserName() {
	return Cookies.get(UserName) || null
}

export function setUserName(token) {
	return Cookies.set(UserName, token)
}

export function removeUserName() {
	return Cookies.remove(UserName)
}

export function getUserAvatar() {
	return Cookies.get(UserAvatar) || null
}

export function setUserAvatar(token) {
	return Cookies.set(UserAvatar, token)
}

export function removeUserAvatar() {
	return Cookies.remove(UserAvatar)
}


export function getToken() {
	return Cookies.get(TokenKey) || null
}

export function setToken(token) {
	return Cookies.set(TokenKey, token)
}

export function removeToken() {
	return Cookies.remove(TokenKey)
}

export function getAccessKey() {
	return JSON.parse(Cookies.get('AccessKey') || null)
}

export function setAccessKey(AccessKey) {
	return Cookies.set('AccessKey', JSON.stringify(AccessKey))
}

export function removeAccessKey() {
	return Cookies.remove('AccessKey')
}