import request from '@/utils/request'

export function getUnitList(query) {
	return request({
		url: '/units',
		method: 'get',
		params: query
	})
}

export function getUnitforOne(id, query) {
	return request({
		url: '/users/' + id + '/unit',
		method: 'get',
		params: query
	})
}

export function createUnitforOne(id, data) {
	return request({
		url: '/users/' + id + '/unit',
		method: 'post',
		data: data
	})
}

export function getUnitforOneCount(id, query) {
	return request({
		url: '/users/' + id + '/unit/count',
		method: 'get',
		params: query
	})
}


export function updateUnitforOne(userid, unitid, data) {
	return request({
		url: '/users/' + userid + '/unit/' + unitid,
		method: 'put',
		data
	})
}

export function getUnitforOnearc(userid, unitid) {
	return request({
		url: '/users/' + userid + '/unit/' + unitid,
		method: 'get',
	})
}

export function deleteUnitforOnearc(userid, unitid) {
	return request({
		url: '/users/' + userid + '/unit/' + unitid,
		method: 'delete',
	})
}

// 修改管理人员
export function postUnitAdam(unitid, userid, type) {
	return request({
		url: '/units/' + unitid + '/' + userid + '/' + type + '/changeAdministrators',
		method: 'post',
	})
}
// 获取管理员信息
export function getUnitAdam(unitid) {
	return request({
		url: '/units/' + unitid + '/userAdmins',
		method: 'get',
	})
}
// 获取一个管理员信息
export function getUnitAdamforOne(unitid, userid) {
	return request({
		url: '/units/' + unitid + '/userAdmins/' + userid,
		method: 'get',
	})
}


//删除单位人员
export function deleteUnitUser(userid, unitid) {
	return request({
		url: '/units/' + unitid + '/' + userid + '/deleteUser',
		method: 'delete',
	})
}


//单位数据


export function getunitBioData(data) {
	return request({
		url: '/units/getUnitBioData',
		method: 'get',
		params: data
	})
}


//获取单位文章

export function getunitArticle(data) {
	return request({
		url: '/units/getUnitArticle',
		method: 'get',
		params: data

	})
}

export function getUserAudits(id, data) {
	return request({
		url: '/users/' + id + '/userAudits',
		method: 'get',
		params: data
	})
}