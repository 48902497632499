import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/views/layout/layout.vue';
import appLayout from '@/layout/appLayout.vue';
import { getToken } from '@/utils/auth';

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

import cloudToolsRouter from './modules/cloud-tool.js';

const routes = [
  {
    path: '/',
    component: Layout,
    meta: {
      title: '首页',
    },
    children: [
      {
        path: '/',
        name: '/',
        component: () => import('@/views/home'),
        hidden: true,
      },
      {
        //热门搜索
        path: '/getHotsList',
        name: 'getHotsList',
        component: () => import('@/views/home/getHotsList'),
      },
      {
        //文章
        path: '/article',
        name: 'article',
        component: () => import('@/views/article/index'),
      },
      {
        //文章内容
        path: '/articleContent',
        name: 'articleContent',
        component: () => import('@/views/article/articleContent'),
      },
      {
        //云工具
        path: '/cloudTools',
        name: 'cloudTools',
        component: () => import('@/views/cloudTools'),
      },
      {
        path: '/diodataContent',
        name: 'diodataContent',
        component: () => import('@/views/personalCenter/diodataContent.vue'),
      },
    ],
  },
  {
    //注册
    path: '/register',
    name: 'register',
    component: () => import('@/views/login/register.vue'),
  },
  {
    //登录
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login.vue'),
  },
  {
    //重置密码
    path: '/changePassword',
    name: 'changePassword',
    component: () => import('@/views/login/changePassword.vue'),
  },
  {
    path: '/appLayout',
    component: appLayout,
    meta: {
      title: '首页',
    },
    children: [
      {
        //单位信息
        path: '/unitInfo',
        name: 'unitInfo',
        component: () => import('@/views/personalCenter/components/unitInfo.vue'),
      },
      {
        //单位人员
        path: '/unitPerson',
        name: 'unitPerson',
        component: () => import('@/views/personalCenter/components/unitPerson.vue'),
      },
      {
        //人员审核
        path: '/personnelReview',
        name: 'personnelReview',
        component: () => import('@/views/personalCenter/components/personnelReview.vue'),
      },
      {
        //单位数据
        path: '/unitData',
        name: 'unitData',
        component: () => import('@/views/personalCenter/components/unitData.vue'),
      },
      {
        //我的数据
        path: '/myData',
        name: 'myData',
        component: () => import('@/views/personalCenter/components/myData.vue'),
      },
      {
        //上传数据
        path: '/uploadData',
        name: 'uploadData',
        component: () => import('@/views/personalCenter/components/uploadData.vue'),
      },
      {
        //单位文章
        path: '/unitArticle',
        name: 'unitArticle',
        component: () => import('@/views/personalCenter/components/unitArticle.vue'),
      },
      {
        //我的文章
        path: '/myArticle',
        name: 'myArticle',
        component: () => import('@/views/personalCenter/components/myArticle.vue'),
      },
      {
        //我的收藏
        path: '/perInformation',
        name: 'perInformation',
        component: () => import('@/views/personalCenter/components/perInformation.vue'),
      },
      {
        //个人信息
        path: '/collectIndex',
        name: 'collectIndex',
        component: () => import('@/views/personalCenter/components/collectIndex.vue'),
      },
      {
        path: '/createUnitInfo',
        name: 'createUnitInfo',
        component: () => import('@/views/personalCenter/components/createUnitInfo.vue'),
      },
      {
        path: '/postUnitInfo',
        name: 'postUnitInfo',
        component: () => import('@/views/personalCenter/components/postUnitInfo.vue'),
      },

      {
        path: '/createMyArticle',
        name: 'createMyArticle',
        component: () => import('@/views/personalCenter/components/createMyArticle.vue'),
      },
      {
        path: '/addUnit',
        name: 'addUnit',
        component: () => import('@/views/personalCenter/components/addUnit.vue'),
      },
      {
        path: '/postperInformation',
        name: 'postperInformation',
        component: () => import('@/views/personalCenter/components/postperInformation.vue'),
      },
      {
        path: '/postMyData',
        name: 'postMyData',
        component: () => import('@/views/personalCenter/components/postMyData.vue'),
      },
    ],
  },

  {
    //注册
    path: '/register',
    name: 'register',
    component: () => import('@/views/login/register.vue'),
  },
  {
    //登录
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login.vue'),
  },
  {
    //重置密码
    path: '/changePassword',
    name: 'changePassword',
    component: () => import('@/views/login/changePassword.vue'),
  },
  ...cloudToolsRouter,
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  // 1.如果访问的是登录页面（无需权限），直接放行
  if (
    to.path === '/' ||
    to.path === '/getHotsList' ||
    to.path === '/article' ||
    to.path === '/articleContent' ||
    to.path === '/cloudTools' ||
    to.path === '/about' ||
    to.path === '/diodataContent' ||
    to.path === '/register' ||
    to.path === '/login' ||
    to.path === '/changePassword'
  )
    return next();
  // 2.如果访问的是有登录权限的页面，先要获取token
  const tokenStr = getToken();
  // 2.1如果token为空，强制跳转到登录页面；否则，直接放行
  if (!tokenStr) {
    router.push('/login');
  }
  next();
});

export default router;
