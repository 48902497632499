<template>
	<div>
		<div class="appLogo" @click="getInfo()"></div>

		<div style="margin-top: 24px">
			<el-menu :default-active="router" text-color="#ffffff" active-text-color="#ffffff" @select="handleSelect">
				<el-menu-item v-for="(item, index) in menuList" :key="index" :index="item.value"
					:class="router == item.value ? 'el-menu-hover' : 'el-menu-item'">
					<template slot="title">
						<i :class="router == item.value ? 'el-icon-my-statistic' : ''"></i>
						<span :class="router == item.value ? 'ml-26' : 'ml-27'">{{ item.name }}</span>
					</template>
				</el-menu-item>


				<el-menu-item index="unitArticle" v-if="this.unitId"
					:class="router == 'unitArticle' ? 'el-menu-hover' : 'el-menu-item'">
					<template slot="title">
						<i :class="router == 'unitArticle' ? 'el-icon-my-statistic' : ''"></i>
						<span :class="router == 'unitArticle' ? 'ml-26' : 'ml-27'">单位文章</span>
					</template>
				</el-menu-item>

				<el-menu-item index="unitData" v-if="this.unitId"
					:class="router == 'unitData' ? 'el-menu-hover' : 'el-menu-item'">
					<template slot="title">
						<i :class="router == 'unitData' ? 'el-icon-my-statistic' : ''"></i>
						<span :class="router == 'unitData' ? 'ml-26' : 'ml-27'">单位数据</span>
					</template>
				</el-menu-item>

				<el-menu-item v-if="this.Admin == 'Admin'" index="unitPerson"
					:class="router == 'unitPerson' ? 'el-menu-hover' : 'el-menu-item'">
					<template slot="title">
						<i :class="router == 'unitPerson' ? 'el-icon-my-statistic' : ''"></i>
						<span :class="router == 'unitPerson' ? 'ml-26' : 'ml-27'">单位人员</span>
					</template>
				</el-menu-item>

				<el-menu-item v-if="this.Admin == 'Admin'" index="personnelReview"
					:class="router == 'personnelReview' ? 'el-menu-hover' : 'el-menu-item'">
					<template slot="title">
						<i :class="router == 'personnelReview' ? 'el-icon-my-statistic' : ''"></i>
						<span :class="router == 'personnelReview' ? 'ml-26' : 'ml-27'">人员审核</span>
					</template>
				</el-menu-item>


			</el-menu>
		</div>
	</div>
</template>

<script>
import {
	getUserById
} from '@/api/user'
import {
	getToken,
	getUserId,
	removeToken,
	removeUserId,
	setUnitRole,
	getUnitsId
} from '@/utils/auth'
import {
	getUnitAdamforOne,
	getUnitforOne
} from '@/api/unit'
export default {
	data() {
		return {
			unitId: '',
			Admin: 'User',
			router: 'unitInfo',
			menuList: [{
				name: "单位信息",
				value: "unitInfo"
			},
			{
				name: "我的数据",
				value: "myData"
			}, {
				name: "上传数据",
				value: "uploadData"
			}, {
				name: "我的文章",
				value: "myArticle"
			}, {
				name: "我的收藏",
				value: "collectIndex"
			}, {
				name: "个人信息",
				value: "perInformation"
			}]
		}
	},
	created() {
		this.router = this.$route.name

		this.getUnitAdam()
	},
	watch: {
		$route: {
			handler: function (route) {
				this.router = this.$route.name
				// this.handleSelect(this.$route.name, [this.router])

			},
			immediate: true
		}
	},
	methods: {
		getInfo() {
			this.$router.push('/')
		},
		handleSelect(key, keyPath) {
			// console.log(key, keyPath);
			this.$router.push(`/${key}`)
			this.router = key
		},
		getUnitAdam() {
			getUnitforOne(getUserId()).then(res => {
				this.unitId = res[0].id
				getUnitAdamforOne(this.unitId, getUserId()).then(ress => {
					if (ress) {
						setUnitRole('Admin')
						this.Admin = "Admin"
					} else {
						setUnitRole('User')
					}
				}).catch(errors => {
				})
			}).catch(error => {

			})
		}
	}
}
</script>

<style lang="scss" scoped>
.menu {
	padding-top: 10px;
	float: right;
	height: 40px;
	width: 40px;
	padding-right: 30px;
}

.perName {
	float: right;
	padding-top: 19px;
	padding-right: 44px;
}

.avater {
	float: right;
	height: 30px;
	width: 30px;
	padding-top: 14px;
	padding-right: 10px;
}

.aTitle {
	color: #ffffff;
	font-size: 18px;
	display: block;
	padding-top: 47px;
	padding-left: 19px;
	padding-bottom: 50px;
}

.appLogo {
	margin-top: 24px;
	margin-left: 24px;
	width: 162px;
	height: 71px;
	background: url('../public/appLogo.png');
	background-size: 100%;
	cursor: pointer;
	user-select: none;

}

.sibFavour {
	width: 4px;
	height: 40px;
	background: #ffffff;
	border-radius: 0px 10px 10px 0px;
}

.el-menu {
	border-right: 0px;
	background-color: #ffffff00 !important;
}

.el-menu-item {
	padding-left: 0 !important;
	user-select: none;
}

.el-menu-item:focus {
	background-color: #319ffa !important;
}

.el-menu-item:hover {
	background-image: linear-gradient(to left, #31a1ff 0%, #50afff 100%) !important;
	background-color: rgba(80, 175, 255, 1) !important;
}

.el-menu-hover {
	background-image: linear-gradient(to left, #31a1ff 0%, #50afff 100%) !important;
}

.el-icon-my-statistic {
	width: 4px !important;
	height: 54px !important;
	background: #ffffff;
	border-radius: 0px 10px 10px 0px;
}

.ml-26 {
	margin-left: 22%;
}

.ml-27 {
	margin-left: 27%;
}
</style>